<template>
    <v-container
        id="be-instrum"
        tag="section"
    >
        <v-row
            justify="center"
            v-if="packageAvBox.paytime >= dateNow"
        >        
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Aloe Vera Box
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Рефссылка, инструкции
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                    <p class="mb-7">
                      Алоэ вера бокс - инструмент для продажи аптечки Алоэ и других косметических продуктов на основе этого растения.
                      </p>
                      <p class="mb-10">
                        Цель - Увеличить заинтересованность клиента в Аптечке Алоэ. Увеличить количество продаж. Подобрать свой продукт или серию продуктов на основе алоэ, узнать историю и ценность уникального растения. Ознакомится с частью ассортимента компании. Возможность использования в клиентских чатах и соцсетях. 
                      </p>
                        <v-expansion-panels
                            popout
                            focusable
                            class="exp-panel-title"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span>
                                      <v-icon class="mr-2" color="green">mdi-numeric-1-box-multiple</v-icon>
                                      Твоя реферальная ссылка
                                  </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                                        <router-link
                                            color="success"
                                            :to="{name: 'AvBox', query: { partner: $store.state.user.profile.lr_number }}"
                                        >
                                            Лендинг Aloe Vera Box
                                        </router-link>
                                    </p>
                                    <p class="mb-n2">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                                        <strong>Твоя реферальная ссылка на лендинг Aloe Vera Box</strong>:
                                        <v-text-field
                                            :value="`https://newlvl.net/av-box/?partner=${$store.state.user.profile.lr_number}`"
                                            readonly
                                            id="referLinkAvBox"
                                        ></v-text-field>
                                    </p>
                                    <v-btn
                                        color="gray"
                                        @click="copyRefLinkAvBox"
                                        class="mb-5"
                                    >
                                        Скопировать ссылку
                                    </v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                <span>
                                    <v-icon class="mr-2" color="red">mdi-numeric-2-box-multiple</v-icon>
                                    Инструкция по использованию инструмента
                                </span>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                  <p class="mt-10">
                                    <a href="https://teletype.in/@newlvl21/dLMshLRLBeA" target="_blank">
                                      Как использовать данную страничку и аптечку алоэ вера для увеличения твоего заработка
                                    </a>
                                  </p>    
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span>
                                      <v-icon class="mr-2" color="blue">mdi-numeric-3-box-multiple</v-icon>
                                      Рекомендации по применению и информация о продукции от компании LR
                                  </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                      <a href="https://disk.yandex.com.tr/d/2CQa2mhU3M7Jfg" target="_blank">
                                        Рекомендации по применению от компании LR
                                      </a>
                                    </p>    
                                    <p class="mt-10">
                                      <a href="https://anyflip.com/igxas/qjeq/" target="_blank">
                                        Книга АЛОЭ-ВЕРА (свойства, сертификаты, вопрос-ответ, как применять продукты)
                                      </a>
                                    </p>   
                                    <p class="mt-10">
                                      <a href="/files/avbox/RU_Informacija_o_produkcii.pdf" target="_blank">
                                        Информация о продукции
                                      </a>
                                    </p>                          
                                    <p class="mt-10">
                                      <a href="/files/avbox/Rukovodstvo_dlja_prodazh_AloeVia.pdf" target="_blank">
                                        Руководство для продаж
                                      </a>
                                    </p> 
                                    <p class="mt-10">
                                      <a href="/files/avbox/RU_AVSkinSpecialists.pdf" target="_blank">
                                        Аптечка Алое Вера
                                      </a>
                                    </p> 
                                    <p class="mt-10">
                                      <a href="/files/avbox/RU_List_ocenki_produkcii.pdf" target="_blank">
                                        Лист оценки продукции
                                      </a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/avbox/RU_Spisok_kontaktov.pdf" target="_blank">
                                        Список контактов
                                      </a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/avbox/Social_Media_Guideline_RU.pdf" target="_blank">
                                        РУКОВОДСТВО ПО ИСПОЛЬЗОВАНИЮ МАТЕРИАЛОВ ДЛЯ СОЦИАЛЬНЫХ СЕТЕЙ ДЛЯ ПОСТРОЕНИЯ ВАШЕГО БИЗНЕСА С LR ALOE VIA
                                      </a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/avbox/20170801_LR_ALOE_VIA_-_KICK_OFF_PRESENTATION_RU_KZ.pdf" target="_blank">
                                        ЗАПУСК НОВОГО БРЕНДА АЛОЭ ВЕРА ПО УХОДУ ЗА КОЖЕЙ
                                      </a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/avbox/Ochishchajushchaja_penka_Aloe_Via.pdf" target="_blank">
                                        Очищающая пенка
                                      </a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/avbox/03.2021_New_AV_Men_Presentaition.pdf" target="_blank">
                                        Aloe Via для мужчин
                                      </a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="https://teletype.in/@newlvl21/Z_bfy4l8FST" target="_blank">
                                        Аптечка. Рекомендации к применению.
                                      </a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="https://teletype.in/@newlvl21/QjvoeE6BMlC" target="_blank">
                                        ОСНОВНЫЕ ГИНЕКОЛОГИЧЕСКИЕ ЗАБОЛЕВАНИЯ, КОТОРЫЕ МОЖНО ВЫЛЕЧИТЬ С ПОМОЩЬЮ АЛОЭ ВЕРА.
                                      </a>
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span>
                                      <v-icon class="mr-2" color="purple">mdi-numeric-4-box-multiple</v-icon>
                                      ЭЛЕКТРОННЫЕ КНИГИ ПО АЛОЕ ВЕРА
                                  </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                      <a href="https://sgplus.org/books/doctora-ne-lgut.pdf" target="_blank">Брошюра «Умершие доктора не лгут»</a>
                                    </p>     
                                    <p class="mt-10">
                                      <a href="https://online.anyflip.com/fahuo/mluj/mobile/" target="_blank">
                                        Алоэ вера. Применение и лечение заболеваний</a>
                                    </p>                               
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <!-- <v-expansion-panel>
                                <v-expansion-panel-header>
                                  <span>
                                      <v-icon class="mr-2" color="purple">mdi-numeric-5-box-multiple</v-icon>
                                      Как использовать аптечку при различных заболеваниях
                                  </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                      <a href="https://teletype.in/@newlvl21/QjvoeE6BMlC" target="_blank">
                                        ОСНОВНЫЕ ГИНЕКОЛОГИЧЕСКИЕ ЗАБОЛЕВАНИЯ, КОТОРЫЕ МОЖНО ВЫЛЕЧИТЬ С ПОМОЩЬЮ АЛОЭ ВЕРА.
                                      </a>
                                    </p>     
                                    <p class="mt-10">
                                      <img src="/img/avbox/instr-spray.jpg" alt="" style="max-width: 400px;">
                                      <img src="/img/avbox/instr-conc.jpg" alt="" class="mt-5" style="max-width: 400px;">
                                      <img src="/img/avbox/instr-prop.jpg" alt="" class="mt-5" style="max-width: 400px;">
                                    </p>                               
                                </v-expansion-panel-content>
                            </v-expansion-panel> -->
                        </v-expansion-panels>
                    </v-card-text>

                </base-material-card>
            </v-col>
        </v-row>
        <v-row
            justify="center"
            v-if="!packageAvBox.paytime || packageAvBox.paytime < dateNow"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
            >
                <base-material-card
                    color="red"
                    class="px-md-16 px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Ошибка доступа
                        </div>

                        <div class="subtitle-1 font-weight-light">

                        </div>
                    </template>

                    <v-card-text
                        class="mt-10"
                    >
                        <p>
                            <v-icon
                                color="red"
                                class="mr-2"
                            >
                                mdi-alert-circle-outline
                            </v-icon>
                            У вас нет доступа к просмотру данной страницы.</p>
                        <p>
                            <v-icon
                                class="mr-2"
                                color="blue"
                            >
                                mdi-arrow-right-bold-outline
                            </v-icon>
                            Для получения доступа к инструментам обратитесь к куратору:
                            <router-link
                                color="success"
                                :to="{name: 'PayInstruments'}"
                            >
                                Как получить доступ к инструментам
                            </router-link>
                        </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "AvBoxInstrum",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: ''
    }),
    computed: {
        ...mapGetters({
          packageAvBox: 'user/getProfileAvBox'
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageAvBox: 'user/loadProfileAvBox',
            loadProfilePackage: 'user/loadProfilePackage',
        }),
        copyRefLinkAvBox () {
            let referLinkAvBox = document.getElementById('referLinkAvBox')

            referLinkAvBox.select()

            document.execCommand('copy')
        },
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime
                })
        },
    },
    mounted() {
        this.loadPackageAvBox()
        this.loadProfilePackage()
    }
}
</script>

<style lang="sass">
.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.img-secret
  max-width: 300px
  margin-right: 10px

.img-blic
  max-width: 300px
  margin: 10px
  border: 1px solid #ccc
  border-radius: 10px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0

</style>
